.slick-track{
    display: flex;
    align-items: center;
}
.slick-current {
    border-radius: 50%;
    padding: 3px;
    background: linear-gradient(to right, #d53a9d, #06ebe4);
    width: 8rem !important;
    opacity: 1 !important;
    pointer-events: auto !important;
}
.slick-slide {
    opacity: 0.5;
    margin: 0.3rem;
    pointer-events: none;
}
.slick-list {
    padding: 0 5px !important;
}