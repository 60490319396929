.App {
  overflow: hidden;
  text-align: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-left: -2rem;
  margin-top: -3rem;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  padding: 3px;
  border: 6px solid #d53a9d;
  border-color: #06ebe4 transparent #d53a9d transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1224px) {
  .mobile {
    display: none;
  }
}

@media (max-width: 1223px) {
  .browser {
    display: none;
  }
}
